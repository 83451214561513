import { createSlice } from "@reduxjs/toolkit";
import {
  PushToHistoryPayload,
  SetCurrentPayload,
  SetNextPayload,
  SetPendingPayload,
  TogglePayload,
} from "src/store/types/Message/messageReducer";
import { MessageState } from "src/store/types/Message/messageState";
import { ChatHistoryElement } from "src/types/payload.types";

const initialState: MessageState = {
  history: [],
  next: null,
  answers: {
    sender: "hubert",
    list: [],
  },
  ctyping: false,
  htyping: true,
  pending: null,
  is_rendering: true,
  status: "idle",
};

const MessageSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    nextMessage: (state, action: SetNextPayload) => {
      const { next } = action.payload;
      state.answers = {
        sender: next.sender,
        list: next.answers,
      };
    },

    setCurrent: (state, action: SetCurrentPayload) => {
      const { current } = action.payload;

      if (current) {
        state.answers = {
          sender: current.sender,
          list: current.answers,
        };
      }

      state.current = current;
    },

    setPending: (state, action: SetPendingPayload) => {
      const { pending } = action.payload;
      state.pending = pending;
    },

    moveToHistory: (state) => {
      if (state.answers && state.answers.list.length) {
        const answersWithCreatedAtTime = {
          ...state.answers,
          createdAt: new Date().toISOString(),
        } as ChatHistoryElement;

        state.history.push(answersWithCreatedAtTime);
        state.answers = undefined;
      }
    },

    pushToHistory: (state, action: PushToHistoryPayload) => {
      const { history } = action.payload;
      state.history = state.history.concat(history);
    },

    // Typing
    toggleHubertTyping: (state, action: TogglePayload) => {
      if (action.payload.value !== undefined) {
        return {
          ...state,
          htyping: action.payload.value,
        };
      }

      return state;
    },
    toggleCandidateTyping: (state, action: TogglePayload) => {
      if (action.payload.value !== undefined) {
        return {
          ...state,
          ctyping: action.payload.value,
        };
      }

      return state;
    },
    toggleRendering: (state, action: TogglePayload) => {
      const { value } = action.payload;

      if (value !== undefined) {
        return {
          ...state,
          is_rendering: value,
        };
      }
    },
  },
});

export const {
  nextMessage,
  setCurrent,
  setPending,
  moveToHistory,
  pushToHistory,

  // Toggles
  toggleRendering,
  toggleHubertTyping,
  toggleCandidateTyping,
} = MessageSlice.actions;
export default MessageSlice.reducer;
