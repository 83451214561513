import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingScreen from "src/components/LoadingScreen";
import useConditionalSearchParams from "src/hooks/useConditionalSearchParams";
import { AxiosBaseError } from "src/services/common/axiosBaseQuery/types";
import { useGetInterviewQuery } from "src/services/interview";
import rollbar from "src/services/loggers/rollbar";
import { Interview } from "src/types/interview";
import { ErrorPageProps } from "../ErrorPage";
import LoadPreviewInterview from "./preview/LoadPreviewInterview";

export type InterviewOutletContext = Partial<Interview>;
type LoadInterviewParams = {
  interviewId: string;
};

const LoadInterview = () => {
  const { interviewId } = useParams<LoadInterviewParams>();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useConditionalSearchParams();

  const token = searchParams.get("token");
  const reuse = searchParams.get("reuse");
  const redirectUrl = searchParams.get("redirectUrl");

  const interview = useGetInterviewQuery(
    interviewId ? { id: interviewId, token, reuse, redirectUrl } : skipToken,
    {
      refetchOnReconnect: true,
    }
  );

  useEffect(() => {
    if (interview.isError) {
      rollbar.critical(
        `Interview not loaded for: interviewId ${interviewId}`,
        interview.error
      );
      
      navigate(
        {
          pathname: "/error",
          search: `?${searchParams.toString()}`,
        },
        {
          state: {
            title: "Something went wrong",
            message: "We are sorry for the inconvenience caused. We will reach out to you shortly.",
            status: JSON.stringify((interview.error as AxiosBaseError).data),
          } as ErrorPageProps,
        }
      );
    }
  }, [interview.error, interview.isError, navigate, pathname, searchParams, interviewId]);

  if (interview.isFetching) {
    return <LoadingScreen />;
  }

  return <Outlet />;
};

LoadInterview.Preview = LoadPreviewInterview;

export default LoadInterview;
