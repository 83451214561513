import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EInterviewStateName, TLoadInterviewPayload } from "src/types/payload.types";

export type TInterview = TLoadInterviewPayload & {}

const initialState: TInterview = { 
  _id: "",
  state: {
    name: EInterviewStateName.NotStarted,
    language: "",
    pending: 0,
    current: null,
    resetInterviewCounter: 0
  },
  history: [],
  _state: null
};

const InterviewSlice = createSlice({
  name: "interview",
  initialState,
  reducers: {
    initInterview (
      state,
      action: PayloadAction<{ interview: TLoadInterviewPayload }>
    ) {
      const { interview } = action.payload;

      return {
        _id: interview._id,
        _state: interview._state,
        state: interview.state,
        history: interview.history,
      };
    },

    updateInterviewState (
      state,
      action: PayloadAction<{
        name: 		EInterviewStateName,
        language: string,
        pending: 	number,
        current: 	any,
        resetInterviewCounter: number
      }>
    ) {
      const { name, language, pending, current, resetInterviewCounter } = action.payload;

      return {
        ...state,
        state: {
          name,
          language,
          pending,
          current,
          resetInterviewCounter
        }
      }
    }
  },
});

export const {
  initInterview,
  updateInterviewState,
} = InterviewSlice.actions;
export default InterviewSlice.reducer;
