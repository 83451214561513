import { forwardRef } from "react";
import BaseSingleSelect from "src/components/common/BaseSingleSelect";

// hooks
import { useSendClosedMessageMutation } from "src/services/interview";
import { useAppDispatch, useAppSelector } from "src/store/hooks";

// slices
import {
  moveToHistory,
  nextMessage,
  setCurrent,
  setPending,
  toggleHubertTyping,
  toggleRendering
} from "src/store/slices/MessageSlice";
import { pushReusables } from "src/store/slices/ReusableSlice";

// types
import { EMessageSender } from "src/types/payload.types";
import { Next } from "src/store/types/Message/messageState";
import { Nullable, Optional } from "src/types/global";
import { Current, SingleSelectMessage } from "src/types/interview";
import { updateInterviewState } from "src/store/slices/InterviewSlice";

const SingleSelect = forwardRef<HTMLDivElement>((_, ref) => {
  const dispatch = useAppDispatch();
  const interviewId = useAppSelector((state) => state.Interview._id);
  const question = useAppSelector(
    (state) => state.Messages.current as Optional<Current<SingleSelectMessage>>
  );

  const [sendClosedMessage] = useSendClosedMessageMutation();

  const handleOptionClick = async (
    option: SingleSelectMessage["options"][number]
  ) => {
    if (!question) return;

    let label = option.label;
    const actionLength = option.actions?.length ?? 0;

    if (actionLength > 0) {
      label = option.label.replace(/<\/?[^>]+(>|$)/g, "");
    }

    const candidate_answer: Next = {
      sender:   EMessageSender.Candidate,
      answers:  [label],
      wait:     0,
    };

    let immediateReplies: Nullable<Next> = null;
    if (option.immediate_replies && option.immediate_replies.length > 0) {
      immediateReplies = {
        sender:   EMessageSender.Hubert,
        answers:  option.immediate_replies,
        wait:     0,
      };
    }

    dispatch(moveToHistory());
    dispatch(nextMessage({ next: candidate_answer }));

    dispatch(toggleHubertTyping({ value: true }));

    if (immediateReplies) {
      dispatch(moveToHistory());
      dispatch(nextMessage({ next: immediateReplies }));
      dispatch(moveToHistory());
    }

    const sendCloseMessageResponse = await sendClosedMessage({
      interview_id: interviewId,
      qutter_oid: question._id,
      qutter_id: question.id,
      language: question.language,
      type: question.type,
      text: label,
      value: option.id,
      label,
    });

    if ("data" in sendCloseMessageResponse) {
      const response = sendCloseMessageResponse.data;

      dispatch(toggleRendering({ value: true }));
      dispatch(moveToHistory());

      const interviewState = response.interviewState;
      if (interviewState) {
        dispatch(
          updateInterviewState({
            ...interviewState
          })
        )
      }

      dispatch(setCurrent({
        current:
          (interviewState && interviewState.current) ??
          response.data
      }));
      dispatch(setPending({ 
        pending:
          (interviewState && interviewState.pending) ??
          response.pending
      }));

      const orderLength = response.reusable?.order?.length ?? 0;
      if (orderLength > 0) {
        dispatch(pushReusables(response.reusable));
      }
    } else {
      console.error(sendCloseMessageResponse.error);
    }
  };

  if (!question?.options || !question.options.length) {
    return null;
  }

  return (
    <BaseSingleSelect ref={ref}>
      {question.options.map((option) => (
        <BaseSingleSelect.Option
          key={option.id}
          id={option.id}
          value={option.value}
          onClick={() => handleOptionClick(option)}
        >
          <span dangerouslySetInnerHTML={{ __html: option.label }}></span>
        </BaseSingleSelect.Option>
      ))}
    </BaseSingleSelect>
  );
});

SingleSelect.displayName = "SingleSelect";

export default SingleSelect;
