import clsx from "clsx";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { toggleRendering } from "src/store/slices/MessageSlice";
import CloseEnded from "./CloseEnded";
import SimulateMessages from "../SimulateMessages";

interface HubertMessageProps {
  wait: number;
}

const HubertMessage = ({ wait }: HubertMessageProps) => {
  const dispatch = useAppDispatch();
  const answers = useAppSelector((state) => state.Messages.answers?.list);
  let isTyping = useAppSelector((state) => state.Messages.htyping);

  useEffect(() => {
    dispatch(toggleRendering({ value: true }));
  }, [dispatch]);

  if (!answers) {
    return <span>No answers</span>;
  }

  return (
    <section className="mb-4">
      <div className="current-message-wrapper h-fit">
        <div className={clsx("hubert-avatar", isTyping && "invisible")}>H</div>
        <SimulateMessages
          messages={answers}
          wait={wait}
        />
      </div>
      <CloseEnded />
    </section>
  );
};

export default HubertMessage;
