
export function writeSomething (language: string) {
  let msg = '';

  switch (language) {
    case 'sv':
      msg = 'Skriv ett meddelande...';
      break;
    case 'sv-se':
      msg = 'Skriv ett meddelande...';
      break;
    case 'en':
      msg = 'Write something...';
      break;
    case 'en-gb':
      msg = 'Write something...';
      break;
    case 'en-us':
      msg = 'Write something...';
      break;
    case 'no-no':
      msg = 'Skriv en melding';
      break;
    case 'da-dk':
      msg = 'Skriv en besked';
      break;
    case 'fi-fi':
      msg = 'Kirjoittaa viestin';
      break;
    case 'de-de':
      msg = 'Nachricht schreiben';
      break;
    case 'es-es':
      msg = 'Escribe un mensaje';
      break;
    case 'es-mx':
      msg = 'Escribe un mensaje';
      break;
    case 'fr-fr':
      msg = 'Écrire un message';
      break;
    case 'it-it':
      msg = 'Scrivi qualcosa...';
      break;
    case 'nl-nl':
      msg = 'Schrijf iets...';
      break;
    case 'pt-pt':
      msg = 'Escreva algo...';
      break;
    case 'tr-tr':
      msg = 'Bir şeyler yaz...';
      break;
    case 'el-gr':
      msg = 'Γράψε κάτι...';
      break;
    default:
      msg = 'Write something...';
  }
  return msg;
}

export function sendText (language: string) {
  let text = '';

  switch (language) {
    case 'sv':
      text = 'Skicka';
      break;
    case 'sv-se':
      text = 'Skicka';
      break;
    case 'en':
      text = 'Send';
      break;
    case 'en-gb':
      text = 'Send';
      break;
    case 'en-us':
      text = 'Send';
      break;
    case 'no':
      text = 'Send';
      break;
    case 'no-no':
      text = 'Send';
      break;
    case 'da':
      text = 'Send';
      break;
    case 'da-dk':
      text = 'Send';
      break;
    case 'fi':
      text = 'Lähetä';
      break;
    case 'fi-fi':
      text = 'Lähetä';
      break;
    case 'de':
      text = 'Schicken';
      break;
    case 'de-de':
      text = 'Schicken';
      break;
    case 'es':
      text = 'Enviar';
      break;
    case 'es-es':
      text = 'Enviar';
      break;
    case 'es-mx':
      text = 'Enviar';
      break;
    case 'fr':
      text = 'Envoyer';
      break;
    case 'fr-fr':
      text = 'Envoyer';
      break;
    case 'it-it':
      text = 'Inviare';
      break;
    case 'nl-nl':
      text = 'Verzenden';
      break;
    case 'pt-pt':
      text = 'Enviar';
      break;
    case 'tr-tr':
      text = 'Gönder';
      break;
    case 'el-gr':
      text = 'Αποστολή';
      break;
    default:
      text = 'Send';
  }
  return text;
}

export function multipleSelectDescription (language: string) {
  let text = '';

  switch (language) {
    case 'sv':
      text = 'Klicka i de alternativ som passar bäst i flervalsväljaren nedan.';
      break;
    case 'sv-se':
      text = 'Klicka i de alternativ som passar bäst i flervalsväljaren nedan.';
      break;
    case 'en':
      text = 'Click the options that suits you the best in the multi-choice selector below.';
      break;
    case 'en-gb':
      text = 'Click the options that suits you the best in the multi-choice selector below.';
      break;
    case 'en-us':
      text = 'Click the options that suits you the best in the multi-choice selector below.';
      break;
    case 'no':
      text = 'Klikk på alternativene som passer deg best i flervalgsvelgeren nedenfor.';
      break;
    case 'no-no':
      text = 'Klikk på alternativene som passer deg best i flervalgsvelgeren nedenfor.';
      break;
    case 'da':
      text = 'Klik på de muligheder, der passer dig bedst.';
      break;
    case 'da-dk':
      text = 'Klik på de muligheder, der passer dig bedst.';
      break;
    case 'fi':
      text = 'Napsauta alla olevasta monivalintavalitsimesta sinulle parhaiten sopivia vaihtoehtoja.';
      break;
    case 'fi-fi':
      text = 'Napsauta alla olevasta monivalintavalitsimesta sinulle parhaiten sopivia vaihtoehtoja.';
      break;
    case 'de':
      text = 'Klicken Sie im unten stehenden Auswahlmenü auf die Optionen, die am besten zu Ihnen passen.';
      break;
    case 'de-de':
      text = 'Klicken Sie im unten stehenden Auswahlmenü auf die Optionen, die am besten zu Ihnen passen.';
      break;
    case 'es':
      text = 'Haga clic en las opciones que más le convengan en el selector de opciones múltiples a continuación.';
      break;
    case 'es-es':
      text = 'Haga clic en las opciones que más le convengan en el selector de opciones múltiples a continuación.';
      break;
    case 'es-mx':
      text = 'Haga clic en las opciones que más le convengan en el selector de opciones múltiples a continuación.';
      break;
    case 'fr':
      text = 'Cliquez sur les options qui vous conviennent le mieux dans le sélecteur multi-choix ci-dessous.';
      break;
    case 'fr-fr':
      text = 'Cliquez sur les options qui vous conviennent le mieux dans le sélecteur multi-choix ci-dessous.';
      break;
    case 'it-it':
      text = 'Fai clic sulle opzioni più adatte a te nel selettore a scelta multipla di seguito.';
      break;
    case 'nl-nl':
      text = 'Klik op de opties die het beste bij u passen in de meerkeuzeschakelaar hieronder.';
      break;
    case 'pt-pt':
      text = 'Clique nas opções que melhor se adequam a si no seletor de escolha múltipla abaixo.';
      break;
    case 'tr-tr':
      text = 'Aşağıdaki çoktan seçmeli seçicide size en uygun seçeneklere tıklayın.';
      break;
    case 'el-gr':
      text = 'Κάντε κλικ στις επιλογές που σας ταιριάζουν καλύτερα στον επιλογέα πολλαπλών επιλογών παρακάτω.';
      break;
    default:
      text = 'Click the options that suits you the best in the multi-choice selector below.';
  }
  return text;
}


export function resetInterviewText (language: string) {
  let text = '';

  switch (language) {
    case 'sv-se':
      text = 'Starta om intervjun';
      break;
    case 'en-gb':
      text = 'Restart interview';
      break;
    case 'en-us':
      text = 'Restart interview';
      break;
    case 'no-no':
      text = 'Start intervjuet på nytt';
      break;
    case 'da-dk':
      text = 'Genstart interviewet';
      break;
    case 'fi-fi':
      text = 'Aloita haastattelu uudelleen';
      break;
    case 'de-de':
      text = 'Interview neu starten';
      break;
    case 'es-es':
      text = 'Reiniciar entrevista';
      break;
    case 'es-mx':
      text = 'Reiniciar entrevista';
      break;
    case 'fr-fr':
      text = "Recommencer l'entretien";
      break;
    case 'it-it':
      text = "Ricomincia l'intervista";
      break;
    case 'nl-nl':
      text = 'Interview opnieuw starten';
      break;
    case 'pt-pt':
      text = 'Reiniciar entrevista';
      break;
    case 'tr-tr':
      text = 'Röportajı yeniden başlat';
      break;
    case 'el-gr':
      text = 'Επανεκκίνηση της συνέντευξης';
      break;
    default:
      text = 'Restart interview';
  }
  return text;
}