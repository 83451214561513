import { useEffect } from "react";
import {
  toggleHubertTyping,
  toggleRendering,
} from "src/store/slices/MessageSlice";
import { useAppDispatch } from "src/store/hooks";
import { runAfterSleep } from "src/utils";
import SimulateMessage from "./SimulateMessage";

interface Props {
  messages: string[]
  wait: number;
}

const SimulateMessages = ({
  messages,
  wait,
}: Props) => {
  const dispatch = useAppDispatch();
  let t = 0;
  const delay = 300;

  useEffect(() => {
    if (messages.length === 0) {
      runAfterSleep(() => {
        dispatch(toggleRendering({ value: false }));
        dispatch(toggleHubertTyping({ value: false }));
      }, wait ?? 0);
    }
  }, [dispatch, messages, wait]);

  return (
    <div>
      {messages.map((message, idx) => {
        t += delay;
        return (
          <SimulateMessage
            key={`hubert-message-${idx}-answer-idx-${idx}`}
            wait={wait === 0 ? 0 : t + wait + delay}
            sender="hubert"
            bubble={message}
            is_last_message={idx === messages.length - 1}
          />
        );
      })}
    </div>
  );
};

export default SimulateMessages;
