import { State } from "./interview"

export enum EInterviewStateName {
  NotStarted		= 'not_started',
  Loaded 				= 'interview_loaded',
  Started				= 'interview_started',
  Ended					= 'interview_ended',
  Resumable			= 'interview_resumable'
}

export enum EChatViewMode {
  // User cannot interact with the chat content.
  ReadOnly    = 'readonly',
  // User can interact with the chat content.
  Interactive = 'interactive',
}


export type TJobPayload = {
  _id:      string
  company:  string
  title:    string
}


export type TTemplatePayload = {
  _id: string
  featureFlags: {
    [key:string]: any
  }
}


export type TCandidatePayload = {
  _id: string
  firstName: string,
  lastName: string,
  email: string,
}


export type TApplicationPayload = {
  _id: string
  candidateId: string;
  candidate_id: string;
  jobId: string;
  job_id: string;
  created_at: Date;
}


export enum EMessageSender {
  Candidate   = 'candidate',
  Hubert      = 'hubert',
}


export type ChatHistoryElement = {
  list:       any
  createdAt:  string
  sender:     EMessageSender
}



export type TInterviewUpdateableState = {
  name: 		EInterviewStateName
  language: string,
  pending: 	number
  current:	State['current'] | null
  resetInterviewCounter: number
}


export type TLoadInterviewPayload = {
  _id:      string
  state:    TInterviewUpdateableState
  _state:   State | null
  history:  ChatHistoryElement[]
}


export type TLoadChatPayload = {
  job:          TJobPayload;
  template:     TTemplatePayload;
  candidate:    TCandidatePayload;
  application:  TApplicationPayload;
  interview:    TLoadInterviewPayload;
  reusable:     any
  // reusable: {
  //   company: string;
  //   date: string;
  //   language: string;
  //   role_name: string;
  //   /** Order of reusable ID's for rendering */
  //   order: string[];
  //   /** Reusable Question Map */
  //   map: Record<string, ReusableMessage>;
  // } | null;
  // pending: number;
}


export type TChatOnlinePayload = {
  candidate:      TCandidatePayload;
  interviewState: TInterviewUpdateableState
  // Keep data.current for backward compatibility
  data:           State['current']
  // Keep pending for backward compatibility
  pending:        number
  reusable:       any
}
