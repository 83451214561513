import { BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import { QueryLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { Method } from "axios";

// slices
import { initApplication } from "src/store/slices/ApplicationSlice";
import { initCandidate } from "src/store/slices/CandidateSlice";
import { initInterview } from "src/store/slices/InterviewSlice";
import { initJob } from "src/store/slices/JobSlice";
import { setCurrent } from "src/store/slices/MessageSlice";
import { initTemplate } from "src/store/slices/TemplateSlice";
import { setHeaderOptionNotInterested, setHeaderOptionResetInterview } from "src/store/slices/LayoutControlSlice";
// types
import { TemplateApiReducerPath } from "..";
import { GetTemplateQueryPayload } from "./types";
import { TLoadChatPayload } from "src/types/payload.types";

export type TemplateQueryLifecycle = QueryLifecycleApi<
  GetTemplateQueryPayload,
  BaseQueryFn<{
    url: string;
    method: Method | undefined;
    data?: any;
    params?: Omit<TemplateQueryLifecycle, "templateId">;
  }>,
  TLoadChatPayload,
  typeof TemplateApiReducerPath
>;

async function handleGetTemplateQueryStarted(
  _arg: GetTemplateQueryPayload,
  { queryFulfilled, dispatch }: TemplateQueryLifecycle
) {
  try {
    const { data } = await queryFulfilled;
    if (data) {
      const template = data.template;

      dispatch(initJob({ job: data.job }));
      dispatch(initTemplate({ template }));
      dispatch(initCandidate({ candidate: data.candidate }));
      dispatch(initApplication({ application: data.application }));
      dispatch(initInterview({ interview: data.interview }));

      const current = data.interview.state.current;
      if (current) {
        dispatch(setCurrent({ current }));
      }

      // For testing purposes - remove feature flags when feature has been accepted
      if (template && template.featureFlags && template.featureFlags.reset_interview) {
        const x = template.featureFlags.reset_interview;
        if (x.enabled) {
          dispatch(setHeaderOptionResetInterview(true))
        }
      }
    }
  } catch (e) {
    console.error("getTemplateQuery failed: ", e);
  }
}

const handlers = {
  handleGetTemplateQueryStarted,
};

export default handlers;
