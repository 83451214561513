import clsx from "clsx";
import { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import ProgressBar from "src/components/common/ProgressBar/ProgressBar";
import Text from "src/components/common/Text";
import { useAppSelector } from "src/store/hooks";
import { Breakpoints } from "src/utils/breakpoints";
import HeaderDropdown from "./HeaderDropdown";
import "./Header.scss";

type HeaderProps = ComponentProps<"header">;

const Header = ({ className, ...headerProps }: HeaderProps) => {
  const jobDetails = useAppSelector((state) => state.Job);
  const layout = useAppSelector((state) => state.LayoutControl);
  const interviewLength = useAppSelector(
    (state) => state.Interview._state?.list
  )?.length;
  const isDesktop = useMediaQuery({
    minWidth: Breakpoints.lg,
  });
  const { t } = useTranslation();

  return (
    <header {...headerProps} className={clsx(className, "chat-header-wrapper")}>
      <div className="flex flex-row justify-between">
        <div className="content-wrapper">
          <div className="logo-wrapper">
            <Text weight="semibold" className="letter">
              H
            </Text>
          </div>
          <div className="title-wrapper">
            <Text weight="semibold" block>
              {!isDesktop ? jobDetails?.company : t("hubert")}
            </Text>
            <Text size="small" className="online" block>
              {!isDesktop ? jobDetails?.title : t("online")}
            </Text>
          </div>
        </div>
        {layout.chatHeaderOptions && layout.chatHeaderOptions.resetInterview ? (
          <div className="content-wrapper">
            <HeaderDropdown />
          </div>
        ):(null)}
      </div>
      <ProgressBar interviewLength={interviewLength ?? 1} />
    </header>
  );
};

export default Header;
