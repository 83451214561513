import { createApi } from "@reduxjs/toolkit/query/react";
import { ClosedAnswer, OpenAnswer, ICustomRequest } from "src/store/types/Answers";
import axiosBaseQuery from "../common/axiosBaseQuery";

// types
import {
  TLoadChatPayload,
  TChatOnlinePayload
} from "src/types/payload.types";
import {
  SendFormPayloadsRequest,
  SendReusablesRequest,
} from "./mutations/types";
import { GetInterviewQueryRequest } from "./queries/types";

// handlers
import MutationHandlers from "./mutations/handlers";
import QueryHandlers from "./queries/handlers";

export const InterviewApiV1ReducerPath = "interviewApiV1" as const;
export const InterviewApiV2ReducerPath = "interviewApiV2" as const;

const InterviewApiV1 = createApi({
  reducerPath: InterviewApiV1ReducerPath,
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL!}/interview/v1`,
  }),
  endpoints: (builder) => ({

    getInterview: builder.query<TLoadChatPayload, GetInterviewQueryRequest>({
      query: (data) => {

        if (typeof data === "string") {
          return { url: `/${data}`, method: "GET" };
        }

        const { id, token, reuse, redirectUrl } = data;
        return {
          url: `/${id}`,
          method: "GET",
          params: { token, reuse, redirectUrl },
        };
      },
      onQueryStarted: QueryHandlers.handleGetInterviewQueryStarted,
    }),

    getInterviewPreview: builder.query<TLoadChatPayload, string>({
      query: (id) => ({ url: `/preview/${id}`, method: "GET" }),
      onQueryStarted: QueryHandlers.handleGetInterviewQueryStarted,
    }),

    sendReusables: builder.mutation<TChatOnlinePayload, SendReusablesRequest>({
      query: (data) => ({
        url: `/reusable-reply`,
        method: "POST",
        data: data,
      }),
      onQueryStarted: MutationHandlers.handleSendReusableMutationStarted,
    }),
  }),
});

const InterviewApiV2 = createApi({
  reducerPath: InterviewApiV2ReducerPath,
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL!}/v2/interview`,
  }),
  endpoints: (builder) => ({

    sendClosedMessage: builder.mutation<TChatOnlinePayload, ClosedAnswer>({
      query: (data) => ({ url: `/reply`, method: "POST", data }),
      onQueryStarted: MutationHandlers.handleSendClosedMessageMutationStarted,
    }),

    sendOpenMessage: builder.mutation<TChatOnlinePayload, OpenAnswer>({
      query: (data) => ({ url: `/open-reply`, method: "POST", data }),
      onQueryStarted: MutationHandlers.handleSendOpenMessageMutationStarted,
    }),

    sendFormPayloads: builder.mutation<TChatOnlinePayload, SendFormPayloadsRequest>({
      query: (payloadData) => ({
        url: "/form-payloads",
        method: "POST",
        data: payloadData,
      }),
      onQueryStarted: MutationHandlers.handleSendFormPayloads,
    }),

    sendCustomRequest: builder.mutation<TChatOnlinePayload, ICustomRequest>({
      query: (data) => ({ url: `/custom-request/${data.interviewId}`, method: "POST", data }),
      onQueryStarted: MutationHandlers.handleSendCustomRequestMutationStarted,
    }),

  }),
});

export const {
  useGetInterviewQuery,
  useGetInterviewPreviewQuery,
  useSendReusablesMutation,
} = InterviewApiV1;

export const {
  useSendClosedMessageMutation,
  useSendOpenMessageMutation,
  useSendFormPayloadsMutation,
  useSendCustomRequestMutation,
} = InterviewApiV2;

const InterviewApi = { v1: InterviewApiV1, v2: InterviewApiV2 };

export default InterviewApi;
